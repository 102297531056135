exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-counternarratives-js": () => import("./../../../src/pages/counternarratives.js" /* webpackChunkName: "component---src-pages-counternarratives-js" */),
  "component---src-pages-fictions-js": () => import("./../../../src/pages/fictions.js" /* webpackChunkName: "component---src-pages-fictions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-signature-js": () => import("./../../../src/pages/signature.js" /* webpackChunkName: "component---src-pages-signature-js" */),
  "component---src-pages-splash-js": () => import("./../../../src/pages/splash.js" /* webpackChunkName: "component---src-pages-splash-js" */),
  "component---src-templates-architecture-js": () => import("./../../../src/templates/Architecture.js" /* webpackChunkName: "component---src-templates-architecture-js" */),
  "component---src-templates-counter-js": () => import("./../../../src/templates/Counter.js" /* webpackChunkName: "component---src-templates-counter-js" */),
  "component---src-templates-fiction-js": () => import("./../../../src/templates/Fiction.js" /* webpackChunkName: "component---src-templates-fiction-js" */)
}

